<template>
  <div class="flex flex-col lg:flex-row lg:max-w-screen-lg lg:mx-auto xl:max-w-screen-xl">
    <!-- Navigation -->
    <header class="lg:w-1/5">
      <div class="flex justify-between pt-2 sm:pb-2 px-4 lg:flex-col lg:gap-4 lg:sticky lg:top-0">
        <NuxtLink href="/"><img src="public/logo.svg" alt="Western Montana News" class="w-28 lg:w-full" /></NuxtLink>
        <DesktopNavigation />
      </div>
    </header>

    <!-- Main Content -->
    <main class="pb-20 lg:w-5/6">
      <div class="flex flex-col sm:flex-row sm:ring-1 ring-gray-200 dark:ring-gray-800">
        <div class="flex-1">
          <slot />
        </div>
        <aside class="sm:w-80 border-l border-gray-200 dark:border-gray-800">
          <div class="sticky top-0 divide-y dark:divide-gray-800">
            <div class="bg-gray-100 dark:bg-gray-950 shadow-inner sm:shadow-none py-16 sm:py-0">
              <AdsRumbleCube :zone-id="171" />
            </div>
            <slot name="sidebar" />
            <SiteFooter />
          </div>
        </aside>
      </div>
    </main>

    <!-- Mobile Navigation -->
    <div class="fixed bottom-0 left-0 right-0 ring-1 ring-gray-200 dark:ring-gray-800 backdrop-blur-lg bg-white/90 dark:bg-gray-800/90 lg:hidden">
      <MobileNavigation class="lg:hidden" />
    </div>

    <!-- Footer -->
    <!-- <footer class="">
      <span>&copy; 2024 Western Montana News</span>
      <ULink to="/about">About</ULink>
      <ULink to="/privacy">Privacy</ULink>
      <ULink to="/terms">Terms</ULink>
    </footer> -->
  </div>
</template>

<script setup lang="ts">
useHead({
  script: [
    {
      src: 'https://code.jquery.com/jquery-2.2.4.min.js',
      defer: true
    },
    {
      src: 'https://public.radio.co/playerapi/jquery.radiocoplayer.min.js',
      defer: true
    }
  ]
})
</script>